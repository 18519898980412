<template>
    <div>
        <div class="top">
            <div class=""><img class="top-img" src="../static/img/logo.png" alt="" srcset=""> </div>
            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    <div style="width: 60px; text-align: center;"><i class="el-icon-arrow-down el-icon-minus"></i></div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item><a v-scroll-to="{ el: '#home', duration: 500 }">首页</a> </el-dropdown-item>
                    <el-dropdown-item><a v-scroll-to="{ el: '#case', duration: 500 }">产品功能</a> </el-dropdown-item>
                    <el-dropdown-item><a v-scroll-to="{ el: '#anli', duration: 500 }">行业案例</a> </el-dropdown-item>
                    <el-dropdown-item><a v-scroll-to="{ el: '#serve', duration: 500 }">售后服务</a> </el-dropdown-item>
                    <el-dropdown-item><a v-scroll-to="{ el: '#contact', duration: 500 }">合作咨询</a> </el-dropdown-item>
                    <!-- <el-dropdown-item><a  class="top-a" href="http://ss.yidaxz.com" target="_blank">开始制作</a></el-dropdown-item> -->
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="top-home" id="home">
            <div class="top-home-center">
                <div class="top-home-box" @click="goDownLoad">
                    <img class="top-home-img" src="../static/img/down.png" alt="" srcset="">
                    <div>APP下载</div>
                </div>
            </div>
            <div class="top-home-end">
                <div class="top-home-end-title">一搭数字人</div>
                <div>克隆超写实多元分身 一站式数字营销创作服务平台</div>
            </div>
        </div>
        <div class="body" id="case">
            <img class="body-img" src="https://ossstatic.yidaxz.com/www/img1.png" alt="" srcset="">
            <div class="body-end">
                <div class="body-end-title">数字人短视频</div>
                <div>利用先进技术，批量生成脚本，结合海量视频模板，快速制作出高质量内容，多渠道精准投放，覆盖不同人群，有效提升视频的点击率、转化率和曝光度。</div>
            </div>
        </div>
        <div class="body">
            <img class="body-img" src="https://ossstatic.yidaxz.com/www/img2.png" alt="" srcset="">
            <div class="body-end">
                <div class="body-end-title">数字人直播</div>
                <div>真人形象1:1克隆，支持国内外热门直播/短视频平台，7X24小时直播互动不停歇，多样化展示商品，促进直播间活力。</div>
            </div>
        </div>
        <div class="body">
            <img class="body-img" src="https://ossstatic.yidaxz.com/www/img3.png" alt="" srcset="">
            <div class="body-end">
                <div class="body-end-title">数字人实时互动</div>
                <div>注重塑造线上线下统一的品牌形象，通过多模态交互方式，确保在移动端、PC端、Web网页、大屏等，与观众实时互动与参与，提升品牌影响力。</div>
            </div>
        </div>
        <div class="body">
            <img class="body-img" src="https://ossstatic.yidaxz.com/www/img4.png" alt="" srcset="">
            <div class="body-end">
                <div class="body-end-title">数字人翻译</div>
                <div>突破传统限制，提供本地化自然音色，支持多国语言翻译服务，享受无障碍沟通体验，使海外投放更加顺畅。</div>
            </div>
        </div>
        <div class="body-title" id="anli">全局应用 范例展示</div>
        <div class="body-text">强大AI驱动，赋能千行百业，匠心打造精品案例。</div>
        <div class="body-video">
            <div class="body-video-box">
                <VideoCom :key="keyNum" src="https://ossstatic.yidaxz.com/www/1.mp4" :second="3" :width="48"
                    :height="50" />
            </div>
            <div class="body-video-box">
                <VideoCom :key="keyNum" src="https://ossstatic.yidaxz.com/www/2.mp4" :second="3" :width="48"
                    :height="50" />
            </div>
            <div class="body-video-box">
                <VideoCom :key="keyNum" src="https://ossstatic.yidaxz.com/www/3.mp4" :second="3" :width="48"
                    :height="50" />
            </div>
            <div class="body-video-box">
                <VideoCom :key="keyNum" src="https://ossstatic.yidaxz.com/www/4.mp4" :second="3" :width="48"
                    :height="50" />
            </div>
        </div>
        <div class="body-title">量身打造 创意定制</div>
        <div class="body-text">尖端技术，致力打造超写实克隆您的专属数字人。</div>
        <div class="body-info">
            <div class="body-info-box">
                <img class="body-info-img" src="https://ossstatic.yidaxz.com/www/prdimg1.png" alt="" srcset="">
                <div class="body-info-end">
                    <div class="body-info-title">高效人力配置</div>
                    <div>1名创作者+1位客服 全时段在线即时响应</div>
                </div>
            </div>
            <div class="body-info-box">
                <img class="body-info-img" src="https://ossstatic.yidaxz.com/www/prdimg2.png" alt="" srcset="">
                <div class="body-info-end">
                    <div class="body-info-title">AI技术定制</div>
                    <div>按需定制文本 海量虚拟背景 1:1还原真实形象、声音、动作</div>
                </div>
            </div>
            <div class="body-info-box">
                <img class="body-info-img" src="https://ossstatic.yidaxz.com/www/prdimg3.png" alt="" srcset="">
                <div class="body-info-end">
                    <div class="body-info-title">助力降本增效</div>
                    <div>5分钟录制周期缩短 低成本搭建直播/短视频多矩阵</div>
                </div>
            </div>
            <div class="body-info-box">
                <img class="body-info-img" src="https://ossstatic.yidaxz.com/www/prdimg4.png" alt="" srcset="">
                <div class="body-info-end">
                    <div class="body-info-title">强化品牌IP</div>
                    <div>突破传统交互方式 实现沉浸式互动沟通体验</div>
                </div>
            </div>
        </div>
        <div class="body-title" id="serve">专业服务 终身维护</div>
        <div class="body-text">服务终身迭代升级，从PC到移动互联网均有您需要的服务。</div>
        <div class="body">
            <img class="body-img" src="https://ossstatic.yidaxz.com/www/img5.png" alt="" srcset="">
            <div class="body-end">
                <div class="body-end-title">数字人短视频</div>
                <div>利用先进技术，批量生成脚本，结合海量视频模板，快速制作出高质量内容，多渠道精准投放，覆盖不同人群，有效提升视频的点击率、转化率和曝光度。</div>
            </div>
        </div>
        <div class="body">
            <img class="body-img" src="https://ossstatic.yidaxz.com/www/img6.png" alt="" srcset="">
            <div class="body-end">
                <div class="body-end-title">数字人直播</div>
                <div>真人形象1:1克隆，支持国内外热门直播/短视频平台，7X24小时直播互动不停歇，多样化展示商品，促进直播间活力。</div>
            </div>
        </div>
        <div class="body">
            <img class="body-img" src="https://ossstatic.yidaxz.com/www/img7.png" alt="" srcset="">
            <div class="body-end">
                <div class="body-end-title">数字人实时互动</div>
                <div>注重塑造线上线下统一的品牌形象，通过多模态交互方式，确保在移动端、PC端、Web网页、大屏等，与观众实时互动与参与，提升品牌影响力。</div>
            </div>
        </div>
        <div class="body">
            <img class="body-img" src="https://ossstatic.yidaxz.com/www/img8.png" alt="" srcset="">
            <div class="body-end">
                <div class="body-end-title">数字人翻译</div>
                <div>突破传统限制，提供本地化自然音色，支持多国语言翻译服务，享受无障碍沟通体验，使海外投放更加顺畅。</div>
            </div>
        </div>
        <div class="body-title">专业服务 终身维护</div>
        <div class="body-text">服务终身迭代升级，从PC到移动互联网均有您需要的服务。</div>
        <div class="body-info">
            <div class="body-info-box">
                <img class="body-info-img2" src="https://ossstatic.yidaxz.com/www/hzimg1.png" alt="" srcset="">
                <div class="body-info-end">
                    <div class="body-info-title">用户</div>
                </div>
            </div>
            <div class="body-info-box">
                <img class="body-info-img2" src="https://ossstatic.yidaxz.com/www/hzimg2.png" alt="" srcset="">
                <div class="body-info-end">
                    <div class="body-info-title">代理</div>
                </div>
            </div>
            <div class="body-info-box">
                <img class="body-info-img2" src="https://ossstatic.yidaxz.com/www/hzimg3.png" alt="" srcset="">
                <div class="body-info-end">
                    <div class="body-info-title">高级代理商</div>
                </div>
            </div>
            <div class="body-info-box">
                <img class="body-info-img2" src="https://ossstatic.yidaxz.com/www/hzimg4.png" alt="" srcset="">
                <div class="body-info-end">
                    <div class="body-info-title">OEM定制</div>
                </div>
            </div>
        </div>
        <div class="end-text">一搭致力于高精尖AI智能驱动技术的研发与应用，热忱期待与更多志同道合的合作伙伴携手，共同探索数字人在多元场景下的创新应用，为人们的日常生活带来前所未有的便捷与惊喜。</div>

        <div class="contactBox" id="contact">
            <div class="contactBox-title">联系我们</div>
            
            <div class="contactBox-box">
                <div class="contactBox-name">公司名称：</div>
                <div>徐州一搭数字科技有限公司</div>
            </div>
            <div class="contactBox-box">
                <div class="contactBox-name">客服热线：</div>
                <div>180 2057 2918</div>
            </div>
            <div class="contactBox-box">
                <div class="contactBox-name">公司地址：</div>
                <div>江苏省徐州市铜山区黄河路1号</div>
            </div>
        </div>
        <div class="tab">
            <div class="tab-box">

                <a v-scroll-to="{ el: '#home', duration: 500 }">
                    <img class="tab-img" src="../static/img/icon1.png" alt="" srcset="">
                    <div>首页</div>
                </a>
            </div>
            <div class="tab-box">
                <a v-scroll-to="{ el: '#case', duration: 500 }">
                    <img class="tab-img" src="../static/img/icon2.png" alt="" srcset="">
                    <div>产品功能</div>
                </a>
            </div>
            <div class="tab-box">
                <a v-scroll-to="{ el: '#anli', duration: 500 }">
                    <img class="tab-img" src="../static/img/icon3.png" alt="" srcset="">
                    <div>行业案例</div>
                </a>
            </div>
            <div class="tab-box">

                <a v-scroll-to="{ el: '#serve', duration: 500 }">
                    <img class="tab-img" src="../static/img/icon4.png" alt="" srcset="">
                    <div>售后服务</div>
                </a>
            </div>

            <div class="tab-box">
                <a v-scroll-to="{ el: '#contact', duration: 500 }">
                    <img class="tab-img" src="../static/img/icon5.png" alt="" srcset="">
                    <div>合作咨询</div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import VideoCom from '@/components/Video.vue';
export default {
    name: 'homeIndex',
    components: {
        VideoCom
    },
    data() {
        return {
            keyNum: 0,
            screenWidth: 0,
            screenHeight: 0,
            src: require('@/static/video/video.mp4'), // 相对路径
            // src: 'https://cdn.jsdelivr.net/gh/themusecatcher/resources@0.0.3/Bao.mp4',
            // poster: require('@/assets/images/Bao.jpg'),
            // poster: 'https://cdn.jsdelivr.net/gh/themusecatcher/resources@0.0.3/bao.jpg'
        }
    },
    mounted() {
        // 监听屏幕宽度和高度变化
        window.addEventListener('resize', this.handleResize, true)
    },
    methods: {
        handleResize() {
            // console.log(1111)
            // this.screenWidth = window.innerWidth;
            // this.screenHeight = window.innerHeight;
            // this.keyNum++
        },
        goDownLoad() {
            this.$router.push({ path: 'downLoad' })
        }
    }

}
</script>
<style>
body {
    margin: 0px;
    padding: 0px;
}

.top {
    background-color: #C4E4FE;
    width: 100%;
    padding: 13px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-img {
    padding-left: 20px;
    width: 6rem;
}

.top-home {
    height: 11.7rem;
    width: 100%;
    position: relative;
    z-index: 9;
    background-image: url('https://ossstatic.yidaxz.com/home.png');
    background-size: 100% 100%;
}
.top-a{
    color: #5c5b5b;
    text-decoration: none;
}
.top-home-box {
    background-color: #fff;
    border-radius: 10px;
    font-size: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 40px;
    padding: 10px;
}

.top-home-center {
    padding-top: 40px;
    padding-left: 20px;
}

.top-home-img {
    width: 31px;
    height: 20px;
}

.top-home-end {
    color: #fff;
    font-size: 0.7rem;
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.top-home-end-title {
    font-size: 1rem;
}

.body {
    text-align: center;
    padding-bottom: 10px;
}

.body-img {
    height: 8.5rem;
    width: 95%;
}

.body-end {
    text-align: center;
    font-size: 0.7rem;
    width: 80%;
    margin: 0 auto;
}

.body-end-title {
    font-size: 0.8rem;
    line-height: 1.4rem;
    font-weight: bold;
}

.body-title {
    font-weight: bold;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 4px;
}

.body-text {
    text-align: center;
    font-size: 0.7rem;
    padding-bottom: 20px;
}

.body-video {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 98%;
    margin: 0 auto;
}

.body-video-box {
    padding-bottom: 10px;
}

.body-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 98%;
    margin: 0 auto;
    font-size: 0.6rem;

}

.body-info-img {
    width: 95%;
    height: 22rem;
}

.body-info-img2 {
    width: 95%;
    height: 16.8rem;
}

.body-info-end {
    text-align: center;
    padding-bottom: 20px;
    width: 70%;
    margin: 0 auto;
}

.body-info-box {
    width: 50%;
    text-align: center;
}

.body-info-title {
    font-size: 0.8rem;
    line-height: 1.4rem;
    font-weight: bold;
}

.end-text {
    font-size: 0.7rem;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 20px;
    text-align: center;
}

.contactBox {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 20px;
}

.contactBox-title {
    padding-bottom: 20px;
    font-weight: bold;
}

.contactBox-box {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    padding-bottom: 6px;
}

.tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    font-size: 0.6rem;
    padding-bottom: 20px;
}

.tab-img {
    width: 20px;
    height: 20px;
}

.tab-box {
    text-align: center;
    width: 20%;
}
</style>