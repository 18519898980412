<template>
  <div >
      <div class="tabBox">
          <div class="home-tab" id="home"> 
              <div class="home-tab-info">
                  <div class="home-tab-text"><img class="home-img" src="../static/img/logo.png" alt="" srcset=""> </div>
                  <div class="home-tab-text cursor"><a v-scroll-to="{ el: '#home', duration: 500 }">首页</a></div>
                  <div class="home-tab-text cursor"><a v-scroll-to="{ el: '#case', duration: 500  }">行业案例</a></div>
                  <div class="home-tab-text cursor"><a v-scroll-to="{ el: '#serve', duration: 500 }">售后服务</a></div>
                  <div class="home-tab-text cursor"><a v-scroll-to="{ el: '#contact', duration: 500 }">合作咨询</a></div>
                  <!-- <div class="home-tab-text cursor"><a href="http://ss.yidaxz.com" target="_blank">开始制作</a></div> -->
              </div>
              <div class="home-downloadBox">
                  <img class="home-download" src="../static/img/downLoad.png" alt="" srcset="">
                  <img class="home-qrcode" src="../static/img/qrcode.png" alt="" srcset="">
              </div>
          </div>
      </div>
      <div class="home" >
          
          <div class="home-end">
              <div class="home-end-name">一搭数字人</div>
              <div class="home-end-text">克隆超写实多元分身 一站式数字营销创作服务平台</div>
          </div>
      </div>
      <div class="product" id="product">
          <div class="product-box">
              <div > <img class="product-box-img" src="https://ossstatic.yidaxz.com/www/img1.png" alt="" srcset=""> </div>
              <div class="product-box-text">
                  <div class="product-box-text-title textLeft">数字人短视频</div>
                  <div class="product-box-text-content textLeft">利用先进技术，批量生成脚本，结合海量视频模板，快速制作出高质量内容，多渠道精准投放，覆盖不同人群，有效提升视频的点击率、转化率和曝光度。</div>
              </div>
          </div>
          <div class="product-box">
              <div class="product-box-text">
                  <div class="product-box-text-title textright ">数字人直播</div>
                  <div class="product-box-text-content textright">真人形象1:1克隆，支持国内外热门直播/短视频平台，7X24小时直播互动不停歇，多样化展示商品，促进直播间活力。</div>
              </div>
              <div > <img class="product-box-img" src="https://ossstatic.yidaxz.com/www/img2.png" alt="" srcset=""> </div>
          </div>
          <div class="product-box">
              <div > <img class="product-box-img" src="https://ossstatic.yidaxz.com/www/img3.png" alt="" srcset=""> </div>
              <div class="product-box-text">
                  <div class="product-box-text-title textLeft">数字人实时互动</div>
                  <div class="product-box-text-content textLeft">注重塑造线上线下统一的品牌形象，通过多模态交互方式，确保在移动端、PC端、Web网页、大屏等，与观众实时互动与参与，提升品牌影响力。</div>
              </div>
          </div>
          <div class="product-box">
              <div class="product-box-text">
                  <div class="product-box-text-title textright ">数字人翻译</div>
                  <div class="product-box-text-content textright">突破传统限制，提供本地化自然音色，支持多国语言翻译服务，享受无障碍沟通体验，使海外投放更加顺畅。</div>
              </div>
              <div > <img class="product-box-img" src="https://ossstatic.yidaxz.com/www/img4.png" alt="" srcset=""> </div>
              
          </div>
      </div>
      <div id="case" class="case">
          <div class="case-top">
              <div class="case-title">全局应用 范例展示</div>
              <div class="case-text">强大AI驱动，赋能千行百业，匠心打造精品案例。</div>
          </div>
          <div class="video-box">
              <div class="video-box-room">
                  <VideoCom :key="keyNum" src="https://ossstatic.yidaxz.com/www/1.mp4" :second="3"  />
              </div>
              <div class="video-box-room">
                  <VideoCom :key="keyNum" src="https://ossstatic.yidaxz.com/www/2.mp4" :second="3" />
              </div>
              <div class="video-box-room">
                  <VideoCom :key="keyNum" src="https://ossstatic.yidaxz.com/www/3.mp4" :second="3" />
              </div>
              <div class="video-box-room">
                  <VideoCom :key="keyNum" src="https://ossstatic.yidaxz.com/www/4.mp4" :second="3" />
              </div>
          </div>
      </div>
      <div id="case-secend" class="case">
          <div class="case-top">
              <div class="case-title">量身打造 创意定制</div>
              <div class="case-text">尖端技术，致力打造超写实克隆您的专属数字人。</div>
          </div>
          <div class="secend-info">
              <div class="secend-box">
                  <img class="secend-img" src="https://ossstatic.yidaxz.com/www/prdimg1.png" alt="" srcset="">
                  <div >
                      <div class="secend-box-name">高效人力配置</div>
                      <div class="secend-box-text">1名创作者+1位客服 全时段在线即时响应</div>
                  </div>
              </div>
              <div class="secend-box">
                  <img class="secend-img" src="https://ossstatic.yidaxz.com/www/prdimg2.png" alt="" srcset="">
                  <div >
                      <div class="secend-box-name">AI技术定制</div>
                      <div class="secend-box-text">按需定制文本 海量虚拟背景 1:1还原真实形象、声音、动作</div>
                  </div>
              </div>
              <div class="secend-box">
                  <img class="secend-img" src="https://ossstatic.yidaxz.com/www/prdimg3.png" alt="" srcset="">
                  <div >
                      <div class="secend-box-name">助力降本增效</div>
                      <div class="secend-box-text">5分钟录制周期缩短 低成本搭建直播/短视频多矩阵</div>
                  </div>
              </div>
              <div class="secend-box">
                  <img class="secend-img" src="https://ossstatic.yidaxz.com/www/prdimg4.png" alt="" srcset="">
                  <div >
                      <div class="secend-box-name">强化品牌IP</div>
                      <div class="secend-box-text">突破传统交互方式 实现沉浸式互动沟通体验</div>
                  </div>
              </div>
          </div>
      </div>
      <div id="serve" class="serve">
          <div class="case-top">
              <div class="case-title">专业服务 终身维护</div>
              <div class="case-text">服务终身迭代升级，从PC到移动互联网均有您需要的服务。</div>
          </div>
          <div class="serve-info">
              <div class="serve-info-box">
                  <img class="serve-img" src="https://ossstatic.yidaxz.com/www/img5.png" alt="" srcset="">
                  <div>
                      <div class="serve-info-name">服务范畴</div>
                      <div class="serve-info-text">数字人制作、APP及小程序开发、H5页面构建，并配以全面技术支持与售后服务。</div>
                  </div>
              </div>
              <div class="serve-info-box">
                  <img class="serve-img" src="https://ossstatic.yidaxz.com/www/img6.png" alt="" srcset="">
                  <div>
                      <div class="serve-info-name">技术支持</div>
                      <div class="serve-info-text">专业技术团队按需全方位定制数字人营销方案，保障方案顺利实施。</div>
                  </div>
              </div>
              <div class="serve-info-box">
                  <img class="serve-img" src="https://ossstatic.yidaxz.com/www/img7.png" alt="" srcset="">
                  <div>
                      <div class="serve-info-name">系统运维</div>
                      <div class="serve-info-text">实施对数字人营销方案定期维护、更新与升级，确保系统稳定可靠。</div>
                  </div>
              </div>
              <div class="serve-info-box">
                  <img class="serve-img" src="https://ossstatic.yidaxz.com/www/img8.png" alt="" srcset="">
                  <div>
                      <div class="serve-info-name">售后保障</div>
                      <div class="serve-info-text">我们承诺终身迭代升级，紧跟市场与客户需求，不断优化技术与服务。</div>
                  </div>
              </div>
          </div>
      </div>
      <div id="case-secend" class="case">
          <div class="case-top" style="padding-top: 10rem;">
              <div class="case-title">合作畅通 前景无限</div>
              <div class="case-text">采取多样化的合作方式，力求与您的故事或品牌完美贴合。</div>
          </div>
          <div class="serve-secend-info">
              <div class="secend-box">
                  <img class="secend-img" src="https://ossstatic.yidaxz.com/www/hzimg1.png" alt="" srcset="">
                  <div >
                      <div class="secend-box-name">用户</div>
                  </div>
              </div>
              <div class="secend-box">
                  <img class="secend-img" src="https://ossstatic.yidaxz.com/www/hzimg2.png" alt="" srcset="">
                  <div >
                      <div class="secend-box-name">代理</div>
                  </div>
              </div>
              <div class="secend-box">
                  <img class="secend-img" src="https://ossstatic.yidaxz.com/www/hzimg3.png" alt="" srcset="">
                  <div >
                      <div class="secend-box-name">高级代理商</div>
                  </div>
              </div>
              <div class="secend-box">
                  <img class="secend-img" src="https://ossstatic.yidaxz.com/www/hzimg4.png" alt="" srcset="">
                  <div >
                      <div class="secend-box-name">OEM定制</div>
                  </div>
              </div>
          </div>
          <div class="serve-end-text">一搭致力于高精尖AI智能驱动技术的研发与应用，热忱期待与更多志同道合的合作伙伴携手，共同探索数字人在多元场景下的创新应用，为人们的日常生活带来前所未有的便捷与惊喜。</div>
      </div>
      <div id="contact" class="contact">
          <div class="contact-title">联系我们</div>
          <div>
              <div class="contact-box">
                  <div class="contact-box-name">公司名称：</div>
                  <div>徐州一搭数字科技有限公司</div>
              </div>
              <div class="contact-box">
                  <div class="contact-box-name">客服热线：</div>
                  <div>180 2057 2918</div>
              </div>
              <div class="contact-box">
                  <div class="contact-box-name">公司地址：</div>
                  <div>江苏省徐州市铜山区黄河路1号</div>
              </div>
              <div class="contact-icpbox">
                <!-- <div>友情链接:</div>
                  <a class="contact-icp" style="color: #000; " href="http://www.downcc.com/about/fabu.html"  target="_blank">绿色资源网</a>
                  <a class="contact-icp" style="color: #000; " href="http://www.ddooo.com"  target="_blank">多多软件站</a>
                  <a class="contact-icp" style="color: #000; " href="https://www.hackhome.com"  target="_blank">嗨客手机站</a> -->
              </div>
              <div style="text-align: center;"><a class="contact-icp" href="https://beian.miit.gov.cn/#/Integrated/index">苏ICP备2024144930号</a></div>
          </div>
      </div>
  </div>
</template>

<script>
 import VideoCom from '@/components/Video.vue';
  export default {
      name: 'homeIndex',
      components: {
          VideoCom
      },
      data () {
          return {
              keyNum:0,
              screenWidth:0,
              screenHeight:0,
            //   src: require('@/static/video/video.mp4'), 
              // src: 'https://cdn.jsdelivr.net/gh/themusecatcher/resources@0.0.3/Bao.mp4',
              // poster: require('@/assets/images/Bao.jpg'),
              // poster: 'https://cdn.jsdelivr.net/gh/themusecatcher/resources@0.0.3/bao.jpg'
          }
      },
      mounted(){
           // 监听屏幕宽度和高度变化
          window.addEventListener('resize', this.handleResize, true)
      },
      methods:{
          handleResize() {
              // console.log(1111)
              // this.screenWidth = window.innerWidth;
              // this.screenHeight = window.innerHeight;
              // this.keyNum++
          }
      }

  }
</script>

<style>
  page{
      margin: 0px;
      padding: 0px;
  }
  .tabBox{
      width: 100%;
      position: relative;
      height: 50px;
  }
  .home{
      height:  95vh;
      width: 100%;
      position: relative;
      z-index: 9;
      background-image: url('https://ossstatic.yidaxz.com/home.png') ;
      background-size: 100% 100%;
  }

  .home-tab{
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      top: 0px;
      z-index: 9999;
      width: 100%;
      background-color: #9da2b2;
  }
  .home-img{
      width: 6rem;
  }

  .home-tab-text{
      width: 15rem;
      text-align: center;
      font-size: 1rem;
      padding: 20px 0px;
      font-weight: bold;
      color: #161E57;
  }
  .home-tab-text a{
      width: 15rem;
      text-align: center;
      font-size: 1rem;
      padding: 20px 0px;
      font-weight: bold;
      color: #161E57;
      text-decoration: none;
  }
  .cursor{
      cursor: pointer;
  }
  .home-end{
      position: absolute;
      bottom: 0px;
      width: 100%;
      text-align: center;
      padding-bottom: 5rem;
      z-index: 9;
  }
  .home-end-name{
      font-weight: bold;
      font-size: 2.2rem;
      padding-bottom: 0.5rem;
      color: #121F7E;
  }
  .home-end-text{
      font-size: 0.8rem;
      color: #121F7E;
  }
  .product{
      height: 100vh;
      text-align: center;
  }
  .product-box{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
  }
  .product-box-img{
      width: 30vw;
  }
  .product-box-text{
      width: 30vw;
  }
  .textLeft{
      text-align: left;
  }
  .textright{
      text-align: right;
  }
  .product-box-text-title{
      font-weight: bold;
      padding-bottom: 10px;
      width: 80%;
      margin: 0 auto;
  }
  .product-box-text-content{
      width: 80%;
      margin: 0 auto;
  }
  .case{
      height: 100vh;
      text-align: center;
  }
  .case-top{
      padding-top: 3.5rem;
  }
  .case-title{
      font-weight: bold;
      font-size: 2.2rem;
      padding-bottom: 0.5rem;
      letter-spacing: 2px;
  }
  .case-text{
      font-size: 0.8rem;
  }
  .video-box{
      display: flex;
      align-items: center;
      /* flex-wrap: wrap; */
      width: 66.5%;
      margin: 0 auto;
  }
  .video-box-room{
      margin-right: 1rem;
      padding-top: 10rem;
      width: 29vw;
  }
  .case-video{
      /* width: 18rem; */
      margin-right: 20px;
  }
  .secend-info{
      width: 68%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding-top: 3rem;
  }
  .serve-secend-info{
      width: 68%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding-top: 4rem;
  }
  .secend-img{
      width: 15vw;
  }
  .secend-box{
      text-align: center;
      width: 25%;
  }
  .secend-box-name{
      padding-top: 2rem;
      font-weight: bold;
      font-size: 1.2rem;
  }
  .serve{
      height: 100vh;
      text-align: center;
  }
  .serve-info{
      display: flex;
      /* align-items: center; */
      justify-content: space-between;
      flex-wrap: wrap;
      width: 75%;
      margin: 0 auto;
      padding-top: 3.6rem;
  }
  .serve-img{
      width: 29vw;
      padding-bottom: 1.5rem;
  }
  .serve-info-box{
      width: 50%;
      text-align: center;
      
  }
  .serve-info-name{
      font-weight: bold;
      font-size: 1.2rem;
  }
  .serve-info-text{
      width: 50%;
      margin: 0 auto;
      padding-top: 0.4rem;
      padding-bottom: 2.6rem;
      font-size: 0.8rem;
      
  }
  .serve-end-text{
      width: 40%;
      margin: 0 auto;
      padding-top: 5rem;
      font-size: 1rem;
  }
  .contact{
      height: 50vh;
      width: 65%;
      margin: 0 auto;
  }
  .contact-title{
      font-weight: bold;
      font-size: 2.3rem;
      padding-top: 5rem;
  }
  .contact-box{
      display: flex;
      align-items: center;
      padding-top: 2rem;
      font-size: 1rem;
  }
  .contact-icpbox{
      text-align: center;
      padding-top: 10rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
  }
  .contact-icp{
      text-decoration: none;
      color: #000;
      margin-left: 20px;
  }
  .home-tab-info{
      display: flex;
      align-items: center;
  }
  .home-download{
     width: 9rem; 
     margin-right: 40px;
     position: relative;
     cursor: pointer;
  }
  .home-downloadBox:hover .home-qrcode{
      visibility: visible;
  }
  .home-qrcode{
      width: 10rem;
      visibility: hidden;
      position: fixed;
      width: 10rem;
      top: 4rem;
      right: 2rem;
  }
</style>
